import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, UseMutationOptions, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables, headers?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BalanceModel = {
  __typename?: 'BalanceModel';
  advanced: Scalars['Float'];
  assetPriceToday: Scalars['Float'];
  assetPriceYesterday: Scalars['Float'];
  balance: Scalars['Float'];
  cleared: Scalars['Float'];
  movementToday: Scalars['Float'];
  movementTodayFiat: Scalars['Float'];
  spreadPriceToday: Scalars['Float'];
  spreadPriceYesterday: Scalars['Float'];
  spreadValue: Scalars['Float'];
  symbol: Scalars['String'];
  valueToday: Scalars['Float'];
  walletId: Scalars['String'];
  walletRatio?: Maybe<Scalars['Float']>;
  walletType: Scalars['String'];
};

export enum Base {
  Ags = 'AGS',
  Aus = 'AUS',
  Bam = 'BAM',
  Btc = 'BTC',
  Eth = 'ETH'
}

export type Composition = {
  __typename?: 'Composition';
  symbol: Scalars['String'];
  value: Scalars['Float'];
};

export enum DashboardLabel {
  Marketing = 'Marketing',
  Overview = 'Overview'
}

export type Info = {
  __typename?: 'Info';
  version: Scalars['String'];
};

export enum MockTradeSide {
  Buy = 'buy',
  Sell = 'sell'
}

export enum MockTradeStatus {
  Closed = 'closed',
  Open = 'open'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Change the email address for the specified user. */
  changeEmail: Scalars['ID'];
  /** Change the phone number for the specified user. */
  changePhone: Scalars['ID'];
  createPromoCode: Scalars['ID'];
  /** Deactivate BAM rewards for the specified user. */
  deactivateBamRewards: Scalars['ID'];
  deactivatePromoCodes: Array<Scalars['ID']>;
  /** Deactivate the recurring top up feature for the specified user. */
  deactivateRecurringTopUp: Scalars['ID'];
  /** Delete's a user and connections from basiq */
  deleteBasiqUserAndConnection: Scalars['ID'];
  /** Delete the underlying cognito login for the specified user. */
  deleteUser: Scalars['ID'];
  /** Disable the underlying cognito login for the specified user. */
  disableUser: Scalars['ID'];
  /** Get sellable assets for the user */
  getAssetBalance: Scalars['Float'];
  /** Pause roundups for the specified user. */
  pauseRoundups: Scalars['ID'];
  /** Get the number of pending payments for the user */
  pendingPaymentsCount: Scalars['Int'];
  /**
   * Process a sila withdrawal for a specific payment.
   * Returns the Payment ID if successful.
   */
  processSilaWithdrawal: Scalars['ID'];
  /**
   * Process a zepto withdrawal for a specific payment.
   * Returns the Payment ID if successful.
   */
  processZeptoWithdrawal: Scalars['ID'];
  /**
   * Force the transactions for the specified user to be resynced with upstream
   * basiq account.
   */
  refreshTransactions: Scalars['ID'];
  /** Retry payments for the specified user. */
  retryPayment?: Maybe<Scalars['ID']>;
  /** Change the daily limit for the specified user. */
  setDailyLimit: Scalars['ID'];
  /** Unlock the bank account for the specified user. */
  unlockBank: Scalars['ID'];
  updatePromoCode: Scalars['ID'];
  /** Commence a withdrawal for of all assets for the specified user */
  withdrawAssets?: Maybe<Scalars['ID']>;
};


export type MutationChangeEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationChangePhoneArgs = {
  phoneNumber: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreatePromoCodeArgs = {
  promoCode: PromoCodeInput;
};


export type MutationDeactivateBamRewardsArgs = {
  userId: Scalars['ID'];
};


export type MutationDeactivatePromoCodesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDeactivateRecurringTopUpArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteBasiqUserAndConnectionArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDisableUserArgs = {
  userId: Scalars['ID'];
};


export type MutationGetAssetBalanceArgs = {
  userId: Scalars['ID'];
};


export type MutationPauseRoundupsArgs = {
  userId: Scalars['ID'];
};


export type MutationPendingPaymentsCountArgs = {
  userId: Scalars['ID'];
};


export type MutationProcessSilaWithdrawalArgs = {
  paymentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationProcessZeptoWithdrawalArgs = {
  paymentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRefreshTransactionsArgs = {
  userId: Scalars['ID'];
};


export type MutationRetryPaymentArgs = {
  paymentId: Scalars['ID'];
};


export type MutationSetDailyLimitArgs = {
  limit: Scalars['Int'];
  userId: Scalars['ID'];
};


export type MutationUnlockBankArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdatePromoCodeArgs = {
  promoCode: PromoCodeInput;
};


export type MutationWithdrawAssetsArgs = {
  userId: Scalars['ID'];
};

export enum Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
};

export type Payment = {
  __typename?: 'Payment';
  OUTSTANDING_PAYMENTS?: Maybe<Scalars['Int']>;
  PORTFOLIO_VALUE?: Maybe<Scalars['Float']>;
  TOTAL_OUTSTANDING?: Maybe<Scalars['Float']>;
  acknowledgedAt?: Maybe<Scalars['String']>;
  advanced?: Maybe<Scalars['Boolean']>;
  amount: Scalars['Float'];
  availableAmount: Scalars['Float'];
  bambooAccountFullname?: Maybe<Scalars['String']>;
  bankAccountFullname?: Maybe<Scalars['String']>;
  baseFee?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  gsiIndex?: Maybe<Scalars['String']>;
  owningUserId?: Maybe<Scalars['ID']>;
  paymentCategory: Scalars['String'];
  paymentDate: Scalars['String'];
  paymentId: Scalars['String'];
  paymentProviderRef?: Maybe<Scalars['String']>;
  paymentTimestamp?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  statusDescription: Scalars['String'];
  tax?: Maybe<Scalars['Float']>;
  userOnboardedBambooAt?: Maybe<Scalars['String']>;
};

export type PaymentEdge = {
  __typename?: 'PaymentEdge';
  cursor: Scalars['String'];
  node: Payment;
};

export type PaymentStatistics = {
  __typename?: 'PaymentStatistics';
  failed: Scalars['Int'];
  failedValue: Scalars['Float'];
  pending: Scalars['Int'];
  pendingValue: Scalars['Float'];
  pendingWithdrawal: Scalars['Int'];
  pendingWithdrawalValue: Scalars['Float'];
  success: Scalars['Int'];
  successValue: Scalars['Float'];
};

export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  edges: Array<PaymentEdge>;
  pageInfo: PageInfo;
};

export type Portfolio = {
  __typename?: 'Portfolio';
  activatedBamRewards?: Maybe<Scalars['Boolean']>;
  activatedPromos: Array<Scalars['String']>;
  basiqConnectionId?: Maybe<Scalars['String']>;
  basiqConnectionStatus?: Maybe<Scalars['String']>;
  billingContactId?: Maybe<Scalars['String']>;
  billingRef?: Maybe<Scalars['String']>;
  composition: Array<Composition>;
  institutionId?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  portfolioId: Scalars['ID'];
  promoActivated?: Maybe<Scalars['Boolean']>;
  roundupAccountId?: Maybe<Scalars['String']>;
  roundupAccountName?: Maybe<Scalars['String']>;
  roundupStrategy?: Maybe<RoundupStrategy>;
  roundupsPaused?: Maybe<Scalars['String']>;
};

export type PortfolioComposition = {
  __typename?: 'PortfolioComposition';
  AGS?: Maybe<Scalars['Float']>;
  AUS?: Maybe<Scalars['Float']>;
  BAM?: Maybe<Scalars['Float']>;
  BTC?: Maybe<Scalars['Float']>;
  ETH?: Maybe<Scalars['Float']>;
};

export type PortfolioEdge = {
  __typename?: 'PortfolioEdge';
  cursor: Scalars['String'];
  node: Portfolio;
};

export type PortfoliosConnection = {
  __typename?: 'PortfoliosConnection';
  edges: Array<PortfolioEdge>;
  pageInfo: PageInfo;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  activationCount: Scalars['Int'];
  active: Scalars['Boolean'];
  details: Array<PromoCodeDetails>;
  endDate?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  startDate?: Maybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type PromoCodeDetails = {
  __typename?: 'PromoCodeDetails';
  percentage: Scalars['Float'];
  symbol: Base;
};

export type PromoCodeDetailsInput = {
  percentage: Scalars['Float'];
  symbol: Base;
};

export type PromoCodeInput = {
  active: Scalars['Boolean'];
  details: Array<PromoCodeDetailsInput>;
  endDate?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  startDate?: InputMaybe<Scalars['Float']>;
  value: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  /** Static support endpoint. */
  about: Info;
  /** Retrieve a paginated list of all bamboo payments. */
  allPayments: PaymentsConnection;
  /**
   * Retrieve a paginated list of all bamboo users. User payments and wallets may be
   * fetched via subquery.
   */
  allUsers: UsersConnection;
  /** Retrieve a Quicksight embedding code for the specified dashboard. */
  dashboardUrl: Scalars['String'];
  paymentStatistics: PaymentStatistics;
  promoCodes: Array<PromoCode>;
  /** Search through a paginated list of all bamboo payments. */
  searchPayments: PaymentsConnection;
  /** Retrieve a paginated list of bamboo users according to a search term. */
  searchUsers: UsersConnection;
  spreadStats: SpreadStatsSet;
  sumMockTradeVolumes: SumMockTradeVolumes;
  /** Retrieve a specific bamboo user. */
  user: User;
  /** Search through a paginated list of all bamboo payments. */
  userPayments: PaymentsConnection;
  userStatistics: UserStatistics;
  walletTotals: WalletTotals;
};


export type QueryAllPaymentsArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryAllUsersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


export type QueryDashboardUrlArgs = {
  label: DashboardLabel;
};


export type QueryPaymentStatisticsArgs = {
  searchPeriod: SearchPeriod;
};


export type QuerySearchPaymentsArgs = {
  advanced?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  searchPeriod?: InputMaybe<SearchPeriod>;
  searchText: Scalars['String'];
  showOutstandingTotals?: InputMaybe<Scalars['Boolean']>;
  showPortfolioValue?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};


export type QuerySearchUsersArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  searchText: Scalars['String'];
};


export type QuerySumMockTradeVolumesArgs = {
  query: SumMockTradeQuery;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserPaymentsArgs = {
  advanced?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  cursorDirection?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  searchPeriod?: InputMaybe<SearchPeriod>;
  showOutstandingTotals?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

export type RoundupStrategy = {
  __typename?: 'RoundupStrategy';
  amount: Scalars['Float'];
  type: Scalars['String'];
};

export type SearchPeriod = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type SpreadStatsModel = {
  __typename?: 'SpreadStatsModel';
  balanceAfter: Scalars['Float'];
  balanceBefore: Scalars['Float'];
  createdAt: Scalars['Int'];
  id: Scalars['String'];
  margin: Scalars['Float'];
  orderType: Scalars['String'];
  paymentType: Scalars['String'];
  symbol: Scalars['String'];
};

export type SpreadStatsSet = {
  __typename?: 'SpreadStatsSet';
  BAM: SpreadStatsModel;
  BTC: SpreadStatsModel;
  ETH: SpreadStatsModel;
};

export type SumMockTradeQuery = {
  endDate?: InputMaybe<Scalars['String']>;
  side: MockTradeSide;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MockTradeStatus>;
};

export type SumMockTradeVolumes = {
  __typename?: 'SumMockTradeVolumes';
  BTC: Scalars['Float'];
  ETH: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  accountType?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  basiqConnectionId?: Maybe<Scalars['String']>;
  basiqConnectionStatus?: Maybe<Scalars['String']>;
  basiqUserId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  dailyLimit?: Maybe<Scalars['Float']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  fundingAccountId?: Maybe<Scalars['String']>;
  fundingAccountName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  institutionName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  onboardedAt?: Maybe<Scalars['String']>;
  paymentsConnection?: Maybe<PaymentsConnection>;
  phoneNumber: Scalars['String'];
  portfolioComposition?: Maybe<PortfolioComposition>;
  portfolioId?: Maybe<Scalars['String']>;
  roundUps?: Maybe<Scalars['Boolean']>;
  roundupAccountId?: Maybe<Scalars['String']>;
  roundupAccountName?: Maybe<Scalars['String']>;
  roundupLimit?: Maybe<Scalars['Float']>;
  roundupStrategyAmount?: Maybe<Scalars['Float']>;
  roundupStrategyType?: Maybe<Scalars['String']>;
  transactionsRefreshedAt?: Maybe<Scalars['String']>;
  walletBalances?: Maybe<WalletBalances>;
};


export type UserPaymentsConnectionArgs = {
  advanced?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  cursorDirection?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Order>;
  searchPeriod?: InputMaybe<SearchPeriod>;
  showOutstandingTotals?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserStatistics = {
  __typename?: 'UserStatistics';
  active: Scalars['Int'];
  bankIssue: Scalars['Int'];
  referrals: Scalars['Int'];
  topUps: Scalars['Int'];
};

export type UsersConnection = {
  __typename?: 'UsersConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type Wallet = {
  __typename?: 'Wallet';
  balance: Scalars['Float'];
  createdAt: Scalars['String'];
  symbol: Scalars['String'];
  walletId: Scalars['ID'];
  walletType: Scalars['String'];
};

export type WalletBalances = {
  __typename?: 'WalletBalances';
  AGS?: Maybe<BalanceModel>;
  AUS?: Maybe<BalanceModel>;
  BAM?: Maybe<BalanceModel>;
  BTC?: Maybe<BalanceModel>;
  ETH?: Maybe<BalanceModel>;
  USDC?: Maybe<BalanceModel>;
};

export type WalletEdge = {
  __typename?: 'WalletEdge';
  cursor: Scalars['String'];
  node: Wallet;
};

export type WalletTotals = {
  __typename?: 'WalletTotals';
  AGS?: Maybe<Scalars['Float']>;
  AUS?: Maybe<Scalars['Float']>;
  BAM?: Maybe<Scalars['Float']>;
  BTC?: Maybe<Scalars['Float']>;
  ETH?: Maybe<Scalars['Float']>;
};

export type ChangePhoneMutationVariables = Exact<{
  userId: Scalars['ID'];
  phoneNumber: Scalars['String'];
}>;


export type ChangePhoneMutation = { __typename?: 'Mutation', changePhone: string };

export type ChangeEmailMutationVariables = Exact<{
  userId: Scalars['ID'];
  email: Scalars['String'];
}>;


export type ChangeEmailMutation = { __typename?: 'Mutation', changeEmail: string };

export type WithdrawAssetsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type WithdrawAssetsMutation = { __typename?: 'Mutation', withdrawAssets?: string | null };

export type SetDailyLimitMutationVariables = Exact<{
  userId: Scalars['ID'];
  limit: Scalars['Int'];
}>;


export type SetDailyLimitMutation = { __typename?: 'Mutation', setDailyLimit: string };

export type UnlockBankMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UnlockBankMutation = { __typename?: 'Mutation', unlockBank: string };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: string };

export type DisableUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser: string };

export type RefreshTransactionsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type RefreshTransactionsMutation = { __typename?: 'Mutation', refreshTransactions: string };

export type DeactivateRecurringTopUpMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeactivateRecurringTopUpMutation = { __typename?: 'Mutation', deactivateRecurringTopUp: string };

export type PauseRoundupsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PauseRoundupsMutation = { __typename?: 'Mutation', pauseRoundups: string };

export type DeactivateBamRewardsMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeactivateBamRewardsMutation = { __typename?: 'Mutation', deactivateBamRewards: string };

export type RetryPaymentMutationVariables = Exact<{
  paymentId: Scalars['ID'];
}>;


export type RetryPaymentMutation = { __typename?: 'Mutation', retryPayment?: string | null };

export type PendingPaymentsCountMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type PendingPaymentsCountMutation = { __typename?: 'Mutation', pendingPaymentsCount: number };

export type GetAssetBalanceMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetAssetBalanceMutation = { __typename?: 'Mutation', getAssetBalance: number };

export type DeleteBasiqUserAndConnectionMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteBasiqUserAndConnectionMutation = { __typename?: 'Mutation', deleteBasiqUserAndConnection: string };

export type ProcessSilaWithdrawalMutationVariables = Exact<{
  userId: Scalars['ID'];
  paymentId: Scalars['ID'];
}>;


export type ProcessSilaWithdrawalMutation = { __typename?: 'Mutation', processSilaWithdrawal: string };

export type ProcessZeptoWithdrawalMutationVariables = Exact<{
  userId: Scalars['ID'];
  paymentId: Scalars['ID'];
}>;


export type ProcessZeptoWithdrawalMutation = { __typename?: 'Mutation', processZeptoWithdrawal: string };

export type UpdatePromoCodeMutationVariables = Exact<{
  promoCode: PromoCodeInput;
}>;


export type UpdatePromoCodeMutation = { __typename?: 'Mutation', updatePromoCode: string };

export type CreatePromoCodeMutationVariables = Exact<{
  promoCode: PromoCodeInput;
}>;


export type CreatePromoCodeMutation = { __typename?: 'Mutation', createPromoCode: string };

export type DeactivatePromoCodesMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeactivatePromoCodesMutation = { __typename?: 'Mutation', deactivatePromoCodes: Array<string> };

export type AllUsersQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type AllUsersQuery = { __typename?: 'Query', allUsers: { __typename?: 'UsersConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'UserEdge', cursor: string, node: { __typename?: 'User', id: string, active: boolean, phoneNumber: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, basiqConnectionId?: string | null, basiqConnectionStatus?: string | null, fundingAccountId?: string | null, email?: string | null } }> } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', fullName?: string | null, firstName?: string | null, lastName?: string | null, middleName?: string | null, active: boolean, phoneNumber: string, basiqUserId?: string | null, basiqConnectionStatus?: string | null, id: string, email?: string | null, createdAt?: string | null, accountType?: string | null, dailyLimit?: number | null, roundUps?: boolean | null, roundupStrategyAmount?: number | null, roundupStrategyType?: string | null, roundupLimit?: number | null, onboardedAt?: string | null, portfolioId?: string | null, institutionName?: string | null, basiqConnectionId?: string | null, fundingAccountId?: string | null, roundupAccountId?: string | null, fundingAccountName?: string | null, roundupAccountName?: string | null, transactionsRefreshedAt?: string | null, walletBalances?: { __typename?: 'WalletBalances', BAM?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null, BTC?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null, ETH?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null, AGS?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null, AUS?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null, USDC?: { __typename?: 'BalanceModel', symbol: string, valueToday: number, balance: number } | null } | null, portfolioComposition?: { __typename?: 'PortfolioComposition', BAM?: number | null, BTC?: number | null, ETH?: number | null, AGS?: number | null, AUS?: number | null } | null } };

export type UserPaymentsQueryVariables = Exact<{
  id: Scalars['ID'];
  order?: InputMaybe<Order>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  advanced?: InputMaybe<Scalars['String']>;
  showOutstandingTotals?: InputMaybe<Scalars['Boolean']>;
  searchPeriod?: InputMaybe<SearchPeriod>;
  cursorDirection?: InputMaybe<Scalars['String']>;
}>;


export type UserPaymentsQuery = { __typename?: 'Query', userPayments: { __typename?: 'PaymentsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PaymentEdge', cursor: string, node: { __typename?: 'Payment', paymentId: string, amount: number, availableAmount: number, paymentDate: string, paymentProviderRef?: string | null, paymentCategory: string, status: string, statusDescription: string, paymentTimestamp?: string | null, acknowledgedAt?: string | null, advanced?: boolean | null, owningUserId?: string | null, baseFee?: number | null, tax?: number | null, fee?: number | null, gsiIndex?: string | null, promoCode?: string | null, bankAccountFullname?: string | null, bambooAccountFullname?: string | null, userOnboardedBambooAt?: string | null, OUTSTANDING_PAYMENTS?: number | null, TOTAL_OUTSTANDING?: number | null } }> } };

export type WalletTotalsQueryVariables = Exact<{ [key: string]: never; }>;


export type WalletTotalsQuery = { __typename?: 'Query', walletTotals: { __typename?: 'WalletTotals', BAM?: number | null, BTC?: number | null, ETH?: number | null, AUS?: number | null, AGS?: number | null } };

export type PromoCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type PromoCodesQuery = { __typename?: 'Query', promoCodes: Array<{ __typename?: 'PromoCode', id: string, startDate?: number | null, endDate?: number | null, value: number, active: boolean, activationCount: number, details: Array<{ __typename?: 'PromoCodeDetails', symbol: Base, percentage: number }> }> };

export type SpreadStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type SpreadStatsQuery = { __typename?: 'Query', spreadStats: { __typename?: 'SpreadStatsSet', BTC: { __typename?: 'SpreadStatsModel', symbol: string, balanceAfter: number }, ETH: { __typename?: 'SpreadStatsModel', symbol: string, balanceAfter: number }, BAM: { __typename?: 'SpreadStatsModel', symbol: string, balanceAfter: number } } };

export type AboutQueryVariables = Exact<{ [key: string]: never; }>;


export type AboutQuery = { __typename?: 'Query', about: { __typename?: 'Info', version: string } };

export type AllPaymentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type AllPaymentsQuery = { __typename?: 'Query', allPayments: { __typename?: 'PaymentsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PaymentEdge', cursor: string, node: { __typename?: 'Payment', paymentId: string, amount: number, availableAmount: number, paymentDate: string, paymentProviderRef?: string | null, paymentCategory: string, status: string, statusDescription: string, paymentTimestamp?: string | null, acknowledgedAt?: string | null, advanced?: boolean | null, owningUserId?: string | null, baseFee?: number | null, tax?: number | null, fee?: number | null, gsiIndex?: string | null, promoCode?: string | null, bankAccountFullname?: string | null, bambooAccountFullname?: string | null, userOnboardedBambooAt?: string | null, OUTSTANDING_PAYMENTS?: number | null, TOTAL_OUTSTANDING?: number | null } }> } };

export type SearchPaymentsQueryVariables = Exact<{
  searchText: Scalars['String'];
  searchPeriod?: InputMaybe<SearchPeriod>;
  limit?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  advanced?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Order>;
  showOutstandingTotals?: InputMaybe<Scalars['Boolean']>;
  showPortfolioValue?: InputMaybe<Scalars['Boolean']>;
}>;


export type SearchPaymentsQuery = { __typename?: 'Query', searchPayments: { __typename?: 'PaymentsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'PaymentEdge', cursor: string, node: { __typename?: 'Payment', paymentId: string, amount: number, availableAmount: number, paymentDate: string, paymentProviderRef?: string | null, paymentCategory: string, status: string, statusDescription: string, paymentTimestamp?: string | null, acknowledgedAt?: string | null, advanced?: boolean | null, owningUserId?: string | null, baseFee?: number | null, tax?: number | null, fee?: number | null, gsiIndex?: string | null, promoCode?: string | null, OUTSTANDING_PAYMENTS?: number | null, TOTAL_OUTSTANDING?: number | null, PORTFOLIO_VALUE?: number | null, bankAccountFullname?: string | null, bambooAccountFullname?: string | null, userOnboardedBambooAt?: string | null } }> } };

export type DashboardUrlQueryVariables = Exact<{
  input: DashboardLabel;
}>;


export type DashboardUrlQuery = { __typename?: 'Query', dashboardUrl: string };

export type PaymentStatisticsQueryVariables = Exact<{
  searchPeriod: SearchPeriod;
}>;


export type PaymentStatisticsQuery = { __typename?: 'Query', paymentStatistics: { __typename?: 'PaymentStatistics', success: number, failed: number, pending: number, pendingWithdrawal: number, successValue: number, failedValue: number, pendingValue: number, pendingWithdrawalValue: number } };

export type UserStatisticsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserStatisticsQuery = { __typename?: 'Query', userStatistics: { __typename?: 'UserStatistics', active: number, topUps: number, referrals: number, bankIssue: number } };

export type SumMockTradeVolumesQueryVariables = Exact<{
  query: SumMockTradeQuery;
}>;


export type SumMockTradeVolumesQuery = { __typename?: 'Query', sumMockTradeVolumes: { __typename?: 'SumMockTradeVolumes', BTC: number, ETH: number } };


export const ChangePhoneDocument = `
    mutation ChangePhone($userId: ID!, $phoneNumber: String!) {
  changePhone(userId: $userId, phoneNumber: $phoneNumber)
}
    `;
export const useChangePhoneMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ChangePhoneMutation, TError, ChangePhoneMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ChangePhoneMutation, TError, ChangePhoneMutationVariables, TContext>(
      ['ChangePhone'],
      (variables?: ChangePhoneMutationVariables) => fetcher<ChangePhoneMutation, ChangePhoneMutationVariables>(client, ChangePhoneDocument, variables, headers)(),
      options
    );
export const ChangeEmailDocument = `
    mutation ChangeEmail($userId: ID!, $email: String!) {
  changeEmail(userId: $userId, email: $email)
}
    `;
export const useChangeEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ChangeEmailMutation, TError, ChangeEmailMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ChangeEmailMutation, TError, ChangeEmailMutationVariables, TContext>(
      ['ChangeEmail'],
      (variables?: ChangeEmailMutationVariables) => fetcher<ChangeEmailMutation, ChangeEmailMutationVariables>(client, ChangeEmailDocument, variables, headers)(),
      options
    );
export const WithdrawAssetsDocument = `
    mutation WithdrawAssets($userId: ID!) {
  withdrawAssets(userId: $userId)
}
    `;
export const useWithdrawAssetsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<WithdrawAssetsMutation, TError, WithdrawAssetsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<WithdrawAssetsMutation, TError, WithdrawAssetsMutationVariables, TContext>(
      ['WithdrawAssets'],
      (variables?: WithdrawAssetsMutationVariables) => fetcher<WithdrawAssetsMutation, WithdrawAssetsMutationVariables>(client, WithdrawAssetsDocument, variables, headers)(),
      options
    );
export const SetDailyLimitDocument = `
    mutation SetDailyLimit($userId: ID!, $limit: Int!) {
  setDailyLimit(userId: $userId, limit: $limit)
}
    `;
export const useSetDailyLimitMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetDailyLimitMutation, TError, SetDailyLimitMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetDailyLimitMutation, TError, SetDailyLimitMutationVariables, TContext>(
      ['SetDailyLimit'],
      (variables?: SetDailyLimitMutationVariables) => fetcher<SetDailyLimitMutation, SetDailyLimitMutationVariables>(client, SetDailyLimitDocument, variables, headers)(),
      options
    );
export const UnlockBankDocument = `
    mutation UnlockBank($userId: ID!) {
  unlockBank(userId: $userId)
}
    `;
export const useUnlockBankMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UnlockBankMutation, TError, UnlockBankMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UnlockBankMutation, TError, UnlockBankMutationVariables, TContext>(
      ['UnlockBank'],
      (variables?: UnlockBankMutationVariables) => fetcher<UnlockBankMutation, UnlockBankMutationVariables>(client, UnlockBankDocument, variables, headers)(),
      options
    );
export const DeleteUserDocument = `
    mutation DeleteUser($userId: ID!) {
  deleteUser(userId: $userId)
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['DeleteUser'],
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(client, DeleteUserDocument, variables, headers)(),
      options
    );
export const DisableUserDocument = `
    mutation DisableUser($userId: ID!) {
  disableUser(userId: $userId)
}
    `;
export const useDisableUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DisableUserMutation, TError, DisableUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DisableUserMutation, TError, DisableUserMutationVariables, TContext>(
      ['DisableUser'],
      (variables?: DisableUserMutationVariables) => fetcher<DisableUserMutation, DisableUserMutationVariables>(client, DisableUserDocument, variables, headers)(),
      options
    );
export const RefreshTransactionsDocument = `
    mutation RefreshTransactions($userId: ID!) {
  refreshTransactions(userId: $userId)
}
    `;
export const useRefreshTransactionsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RefreshTransactionsMutation, TError, RefreshTransactionsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RefreshTransactionsMutation, TError, RefreshTransactionsMutationVariables, TContext>(
      ['RefreshTransactions'],
      (variables?: RefreshTransactionsMutationVariables) => fetcher<RefreshTransactionsMutation, RefreshTransactionsMutationVariables>(client, RefreshTransactionsDocument, variables, headers)(),
      options
    );
export const DeactivateRecurringTopUpDocument = `
    mutation DeactivateRecurringTopUp($userId: ID!) {
  deactivateRecurringTopUp(userId: $userId)
}
    `;
export const useDeactivateRecurringTopUpMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeactivateRecurringTopUpMutation, TError, DeactivateRecurringTopUpMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeactivateRecurringTopUpMutation, TError, DeactivateRecurringTopUpMutationVariables, TContext>(
      ['DeactivateRecurringTopUp'],
      (variables?: DeactivateRecurringTopUpMutationVariables) => fetcher<DeactivateRecurringTopUpMutation, DeactivateRecurringTopUpMutationVariables>(client, DeactivateRecurringTopUpDocument, variables, headers)(),
      options
    );
export const PauseRoundupsDocument = `
    mutation PauseRoundups($userId: ID!) {
  pauseRoundups(userId: $userId)
}
    `;
export const usePauseRoundupsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PauseRoundupsMutation, TError, PauseRoundupsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PauseRoundupsMutation, TError, PauseRoundupsMutationVariables, TContext>(
      ['PauseRoundups'],
      (variables?: PauseRoundupsMutationVariables) => fetcher<PauseRoundupsMutation, PauseRoundupsMutationVariables>(client, PauseRoundupsDocument, variables, headers)(),
      options
    );
export const DeactivateBamRewardsDocument = `
    mutation DeactivateBamRewards($userId: ID!) {
  deactivateBamRewards(userId: $userId)
}
    `;
export const useDeactivateBamRewardsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeactivateBamRewardsMutation, TError, DeactivateBamRewardsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeactivateBamRewardsMutation, TError, DeactivateBamRewardsMutationVariables, TContext>(
      ['DeactivateBamRewards'],
      (variables?: DeactivateBamRewardsMutationVariables) => fetcher<DeactivateBamRewardsMutation, DeactivateBamRewardsMutationVariables>(client, DeactivateBamRewardsDocument, variables, headers)(),
      options
    );
export const RetryPaymentDocument = `
    mutation RetryPayment($paymentId: ID!) {
  retryPayment(paymentId: $paymentId)
}
    `;
export const useRetryPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RetryPaymentMutation, TError, RetryPaymentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RetryPaymentMutation, TError, RetryPaymentMutationVariables, TContext>(
      ['RetryPayment'],
      (variables?: RetryPaymentMutationVariables) => fetcher<RetryPaymentMutation, RetryPaymentMutationVariables>(client, RetryPaymentDocument, variables, headers)(),
      options
    );
export const PendingPaymentsCountDocument = `
    mutation PendingPaymentsCount($userId: ID!) {
  pendingPaymentsCount(userId: $userId)
}
    `;
export const usePendingPaymentsCountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<PendingPaymentsCountMutation, TError, PendingPaymentsCountMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<PendingPaymentsCountMutation, TError, PendingPaymentsCountMutationVariables, TContext>(
      ['PendingPaymentsCount'],
      (variables?: PendingPaymentsCountMutationVariables) => fetcher<PendingPaymentsCountMutation, PendingPaymentsCountMutationVariables>(client, PendingPaymentsCountDocument, variables, headers)(),
      options
    );
export const GetAssetBalanceDocument = `
    mutation GetAssetBalance($userId: ID!) {
  getAssetBalance(userId: $userId)
}
    `;
export const useGetAssetBalanceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetAssetBalanceMutation, TError, GetAssetBalanceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetAssetBalanceMutation, TError, GetAssetBalanceMutationVariables, TContext>(
      ['GetAssetBalance'],
      (variables?: GetAssetBalanceMutationVariables) => fetcher<GetAssetBalanceMutation, GetAssetBalanceMutationVariables>(client, GetAssetBalanceDocument, variables, headers)(),
      options
    );
export const DeleteBasiqUserAndConnectionDocument = `
    mutation deleteBasiqUserAndConnection($userId: ID!) {
  deleteBasiqUserAndConnection(userId: $userId)
}
    `;
export const useDeleteBasiqUserAndConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteBasiqUserAndConnectionMutation, TError, DeleteBasiqUserAndConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteBasiqUserAndConnectionMutation, TError, DeleteBasiqUserAndConnectionMutationVariables, TContext>(
      ['deleteBasiqUserAndConnection'],
      (variables?: DeleteBasiqUserAndConnectionMutationVariables) => fetcher<DeleteBasiqUserAndConnectionMutation, DeleteBasiqUserAndConnectionMutationVariables>(client, DeleteBasiqUserAndConnectionDocument, variables, headers)(),
      options
    );
export const ProcessSilaWithdrawalDocument = `
    mutation ProcessSilaWithdrawal($userId: ID!, $paymentId: ID!) {
  processSilaWithdrawal(userId: $userId, paymentId: $paymentId)
}
    `;
export const useProcessSilaWithdrawalMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProcessSilaWithdrawalMutation, TError, ProcessSilaWithdrawalMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProcessSilaWithdrawalMutation, TError, ProcessSilaWithdrawalMutationVariables, TContext>(
      ['ProcessSilaWithdrawal'],
      (variables?: ProcessSilaWithdrawalMutationVariables) => fetcher<ProcessSilaWithdrawalMutation, ProcessSilaWithdrawalMutationVariables>(client, ProcessSilaWithdrawalDocument, variables, headers)(),
      options
    );
export const ProcessZeptoWithdrawalDocument = `
    mutation ProcessZeptoWithdrawal($userId: ID!, $paymentId: ID!) {
  processZeptoWithdrawal(userId: $userId, paymentId: $paymentId)
}
    `;
export const useProcessZeptoWithdrawalMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ProcessZeptoWithdrawalMutation, TError, ProcessZeptoWithdrawalMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ProcessZeptoWithdrawalMutation, TError, ProcessZeptoWithdrawalMutationVariables, TContext>(
      ['ProcessZeptoWithdrawal'],
      (variables?: ProcessZeptoWithdrawalMutationVariables) => fetcher<ProcessZeptoWithdrawalMutation, ProcessZeptoWithdrawalMutationVariables>(client, ProcessZeptoWithdrawalDocument, variables, headers)(),
      options
    );
export const UpdatePromoCodeDocument = `
    mutation updatePromoCode($promoCode: PromoCodeInput!) {
  updatePromoCode(promoCode: $promoCode)
}
    `;
export const useUpdatePromoCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdatePromoCodeMutation, TError, UpdatePromoCodeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdatePromoCodeMutation, TError, UpdatePromoCodeMutationVariables, TContext>(
      ['updatePromoCode'],
      (variables?: UpdatePromoCodeMutationVariables) => fetcher<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>(client, UpdatePromoCodeDocument, variables, headers)(),
      options
    );
export const CreatePromoCodeDocument = `
    mutation createPromoCode($promoCode: PromoCodeInput!) {
  createPromoCode(promoCode: $promoCode)
}
    `;
export const useCreatePromoCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatePromoCodeMutation, TError, CreatePromoCodeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatePromoCodeMutation, TError, CreatePromoCodeMutationVariables, TContext>(
      ['createPromoCode'],
      (variables?: CreatePromoCodeMutationVariables) => fetcher<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(client, CreatePromoCodeDocument, variables, headers)(),
      options
    );
export const DeactivatePromoCodesDocument = `
    mutation deactivatePromoCodes($ids: [ID!]!) {
  deactivatePromoCodes(ids: $ids)
}
    `;
export const useDeactivatePromoCodesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeactivatePromoCodesMutation, TError, DeactivatePromoCodesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeactivatePromoCodesMutation, TError, DeactivatePromoCodesMutationVariables, TContext>(
      ['deactivatePromoCodes'],
      (variables?: DeactivatePromoCodesMutationVariables) => fetcher<DeactivatePromoCodesMutation, DeactivatePromoCodesMutationVariables>(client, DeactivatePromoCodesDocument, variables, headers)(),
      options
    );
export const AllUsersDocument = `
    query AllUsers($searchText: String, $limit: Int, $cursor: String) {
  allUsers(searchText: $searchText, limit: $limit, cursor: $cursor) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        active
        phoneNumber
        firstName
        lastName
        middleName
        basiqConnectionId
        basiqConnectionStatus
        fundingAccountId
        email
      }
    }
  }
}
    `;
export const useAllUsersQuery = <
      TData = AllUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AllUsersQueryVariables,
      options?: UseQueryOptions<AllUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AllUsersQuery, TError, TData>(
      variables === undefined ? ['AllUsers'] : ['AllUsers', variables],
      fetcher<AllUsersQuery, AllUsersQueryVariables>(client, AllUsersDocument, variables, headers),
      options
    );
export const UserDocument = `
    query User($id: ID!) {
  user(id: $id) {
    fullName
    firstName
    lastName
    middleName
    active
    phoneNumber
    basiqUserId
    basiqConnectionStatus
    id
    email
    createdAt
    accountType
    dailyLimit
    roundUps
    roundupStrategyAmount
    roundupStrategyType
    roundupLimit
    onboardedAt
    portfolioId
    institutionName
    basiqConnectionId
    fundingAccountId
    roundupAccountId
    fundingAccountName
    roundupAccountName
    transactionsRefreshedAt
    walletBalances {
      BAM {
        symbol
        valueToday
        balance
      }
      BTC {
        symbol
        valueToday
        balance
      }
      ETH {
        symbol
        valueToday
        balance
      }
      AGS {
        symbol
        valueToday
        balance
      }
      AUS {
        symbol
        valueToday
        balance
      }
      USDC {
        symbol
        valueToday
        balance
      }
    }
    portfolioComposition {
      BAM
      BTC
      ETH
      AGS
      AUS
    }
  }
}
    `;
export const useUserQuery = <
      TData = UserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserQueryVariables,
      options?: UseQueryOptions<UserQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserQuery, TError, TData>(
      ['User', variables],
      fetcher<UserQuery, UserQueryVariables>(client, UserDocument, variables, headers),
      options
    );
export const UserPaymentsDocument = `
    query UserPayments($id: ID!, $order: Order, $limit: Int, $cursor: String, $status: String, $category: String, $advanced: String, $showOutstandingTotals: Boolean, $searchPeriod: SearchPeriod, $cursorDirection: String) {
  userPayments(
    id: $id
    order: $order
    limit: $limit
    cursor: $cursor
    status: $status
    category: $category
    advanced: $advanced
    showOutstandingTotals: $showOutstandingTotals
    searchPeriod: $searchPeriod
    cursorDirection: $cursorDirection
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        paymentId
        amount
        availableAmount
        paymentDate
        paymentProviderRef
        paymentCategory
        status
        statusDescription
        paymentTimestamp
        acknowledgedAt
        advanced
        owningUserId
        baseFee
        tax
        fee
        gsiIndex
        promoCode
        bankAccountFullname
        bambooAccountFullname
        userOnboardedBambooAt
        OUTSTANDING_PAYMENTS
        TOTAL_OUTSTANDING
      }
    }
  }
}
    `;
export const useUserPaymentsQuery = <
      TData = UserPaymentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserPaymentsQueryVariables,
      options?: UseQueryOptions<UserPaymentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserPaymentsQuery, TError, TData>(
      ['UserPayments', variables],
      fetcher<UserPaymentsQuery, UserPaymentsQueryVariables>(client, UserPaymentsDocument, variables, headers),
      options
    );
export const WalletTotalsDocument = `
    query walletTotals {
  walletTotals {
    BAM
    BTC
    ETH
    AUS
    AGS
  }
}
    `;
export const useWalletTotalsQuery = <
      TData = WalletTotalsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: WalletTotalsQueryVariables,
      options?: UseQueryOptions<WalletTotalsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<WalletTotalsQuery, TError, TData>(
      variables === undefined ? ['walletTotals'] : ['walletTotals', variables],
      fetcher<WalletTotalsQuery, WalletTotalsQueryVariables>(client, WalletTotalsDocument, variables, headers),
      options
    );
export const PromoCodesDocument = `
    query promoCodes {
  promoCodes {
    id
    startDate
    endDate
    details {
      symbol
      percentage
    }
    value
    active
    activationCount
  }
}
    `;
export const usePromoCodesQuery = <
      TData = PromoCodesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: PromoCodesQueryVariables,
      options?: UseQueryOptions<PromoCodesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PromoCodesQuery, TError, TData>(
      variables === undefined ? ['promoCodes'] : ['promoCodes', variables],
      fetcher<PromoCodesQuery, PromoCodesQueryVariables>(client, PromoCodesDocument, variables, headers),
      options
    );
export const SpreadStatsDocument = `
    query SpreadStats {
  spreadStats {
    BTC {
      symbol
      balanceAfter
    }
    ETH {
      symbol
      balanceAfter
    }
    BAM {
      symbol
      balanceAfter
    }
  }
}
    `;
export const useSpreadStatsQuery = <
      TData = SpreadStatsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SpreadStatsQueryVariables,
      options?: UseQueryOptions<SpreadStatsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SpreadStatsQuery, TError, TData>(
      variables === undefined ? ['SpreadStats'] : ['SpreadStats', variables],
      fetcher<SpreadStatsQuery, SpreadStatsQueryVariables>(client, SpreadStatsDocument, variables, headers),
      options
    );
export const AboutDocument = `
    query About {
  about {
    version
  }
}
    `;
export const useAboutQuery = <
      TData = AboutQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AboutQueryVariables,
      options?: UseQueryOptions<AboutQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AboutQuery, TError, TData>(
      variables === undefined ? ['About'] : ['About', variables],
      fetcher<AboutQuery, AboutQueryVariables>(client, AboutDocument, variables, headers),
      options
    );
export const AllPaymentsDocument = `
    query AllPayments($limit: Int, $cursor: String) {
  allPayments(limit: $limit, cursor: $cursor) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        paymentId
        amount
        availableAmount
        paymentDate
        paymentProviderRef
        paymentCategory
        status
        statusDescription
        paymentTimestamp
        acknowledgedAt
        advanced
        owningUserId
        baseFee
        tax
        fee
        gsiIndex
        promoCode
        bankAccountFullname
        bambooAccountFullname
        userOnboardedBambooAt
        OUTSTANDING_PAYMENTS
        TOTAL_OUTSTANDING
      }
    }
  }
}
    `;
export const useAllPaymentsQuery = <
      TData = AllPaymentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AllPaymentsQueryVariables,
      options?: UseQueryOptions<AllPaymentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AllPaymentsQuery, TError, TData>(
      variables === undefined ? ['AllPayments'] : ['AllPayments', variables],
      fetcher<AllPaymentsQuery, AllPaymentsQueryVariables>(client, AllPaymentsDocument, variables, headers),
      options
    );
export const SearchPaymentsDocument = `
    query SearchPayments($searchText: String!, $searchPeriod: SearchPeriod, $limit: Int, $cursor: String, $status: String, $category: String, $advanced: String, $order: Order, $showOutstandingTotals: Boolean, $showPortfolioValue: Boolean) {
  searchPayments(
    searchText: $searchText
    searchPeriod: $searchPeriod
    limit: $limit
    cursor: $cursor
    status: $status
    category: $category
    advanced: $advanced
    order: $order
    showOutstandingTotals: $showOutstandingTotals
    showPortfolioValue: $showPortfolioValue
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        paymentId
        amount
        availableAmount
        paymentDate
        paymentProviderRef
        paymentCategory
        status
        statusDescription
        paymentTimestamp
        acknowledgedAt
        advanced
        owningUserId
        baseFee
        tax
        fee
        gsiIndex
        promoCode
        OUTSTANDING_PAYMENTS
        TOTAL_OUTSTANDING
        PORTFOLIO_VALUE
        bankAccountFullname
        bambooAccountFullname
        userOnboardedBambooAt
      }
    }
  }
}
    `;
export const useSearchPaymentsQuery = <
      TData = SearchPaymentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SearchPaymentsQueryVariables,
      options?: UseQueryOptions<SearchPaymentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SearchPaymentsQuery, TError, TData>(
      ['SearchPayments', variables],
      fetcher<SearchPaymentsQuery, SearchPaymentsQueryVariables>(client, SearchPaymentsDocument, variables, headers),
      options
    );
export const DashboardUrlDocument = `
    query DashboardUrl($input: DashboardLabel!) {
  dashboardUrl(label: $input)
}
    `;
export const useDashboardUrlQuery = <
      TData = DashboardUrlQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: DashboardUrlQueryVariables,
      options?: UseQueryOptions<DashboardUrlQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<DashboardUrlQuery, TError, TData>(
      ['DashboardUrl', variables],
      fetcher<DashboardUrlQuery, DashboardUrlQueryVariables>(client, DashboardUrlDocument, variables, headers),
      options
    );
export const PaymentStatisticsDocument = `
    query PaymentStatistics($searchPeriod: SearchPeriod!) {
  paymentStatistics(searchPeriod: $searchPeriod) {
    success
    failed
    pending
    pendingWithdrawal
    successValue
    failedValue
    pendingValue
    pendingWithdrawalValue
  }
}
    `;
export const usePaymentStatisticsQuery = <
      TData = PaymentStatisticsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: PaymentStatisticsQueryVariables,
      options?: UseQueryOptions<PaymentStatisticsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentStatisticsQuery, TError, TData>(
      ['PaymentStatistics', variables],
      fetcher<PaymentStatisticsQuery, PaymentStatisticsQueryVariables>(client, PaymentStatisticsDocument, variables, headers),
      options
    );
export const UserStatisticsDocument = `
    query UserStatistics {
  userStatistics {
    active
    topUps
    referrals
    bankIssue
  }
}
    `;
export const useUserStatisticsQuery = <
      TData = UserStatisticsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UserStatisticsQueryVariables,
      options?: UseQueryOptions<UserStatisticsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserStatisticsQuery, TError, TData>(
      variables === undefined ? ['UserStatistics'] : ['UserStatistics', variables],
      fetcher<UserStatisticsQuery, UserStatisticsQueryVariables>(client, UserStatisticsDocument, variables, headers),
      options
    );
export const SumMockTradeVolumesDocument = `
    query sumMockTradeVolumes($query: SumMockTradeQuery!) {
  sumMockTradeVolumes(query: $query) {
    BTC
    ETH
  }
}
    `;
export const useSumMockTradeVolumesQuery = <
      TData = SumMockTradeVolumesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SumMockTradeVolumesQueryVariables,
      options?: UseQueryOptions<SumMockTradeVolumesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SumMockTradeVolumesQuery, TError, TData>(
      ['sumMockTradeVolumes', variables],
      fetcher<SumMockTradeVolumesQuery, SumMockTradeVolumesQueryVariables>(client, SumMockTradeVolumesDocument, variables, headers),
      options
    );